import React from 'react';
import { Link } from 'react-router-dom';

const Car = ({ car }) => {
  return (
    <Link to={`/car/${car._id}`}>
      <div className='bg-bono-purple p-1 rounded-lg'>
        <div className="bg-not-so-white p-4 shadow grid grid-cols-3 rounded-md">
            <img src={car.images[0]} alt={car.make} className="col-span-2 w-full h-48 object-cover rounded-lg mb-2" />
            <div className=''>
              <div className='text-left pl-4 text-bono-purple'>
                <h2 className="text-lg font-bold">{car.make}</h2>
                <p className="">{car.model}</p>
                <p className="">{car.year}</p>
                <p className="">{car.price.toLocaleString('en-US', { style: 'currency', currency: 'ISK' })}</p>
                <p className="">{car.fuelType}</p>
                <p className="">Ekið {car.kmDriven}km</p>
              </div>
              <button className="bg-bono-purple hover:bg-gray-800 text-elastic-green font-bold py-2 px-4 mt-4 rounded-full">Skoða</button>
            </div>
        </div>
      </div>
    </Link>
  );
};

export default Car;
