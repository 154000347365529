import React, { useState } from 'react';
import axios from 'axios';

function CarForm() {
  const [car, setCar] = useState({
    make: '',
    model: '',
    year: '',
    price: '',
    fuelType: '',
    kmDriven: '',
    mileage: '',
    description: '',
    images: [''],
  });

  const handleChange = (event) => {
    setCar({
      ...car,
      [event.target.name]: event.target.value,
    });
  };

  const handleImageChange = (index, event) => {
    const newImages = [...car.images];
    newImages[index] = event.target.value;
    // add a new image input field if the last one contains a value or take away the last one if it's empty
    if (index === newImages.length - 1 && event.target.value !== '') {
      newImages.push('');
    }

    if(index === newImages.length - 2 && event.target.value === '') {
      newImages.pop();
    }

    setCar({
      ...car,
      images: newImages,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var carToSend = car;
    carToSend.images = car.images.filter(image => image !== '');

    var token = localStorage.getItem('token');
    try {
      await axios.post(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars`, carToSend, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // console.log(response.data);
      setCar({
        make: '',
        model: '',
        year: '',
        price: '',
        fuelType: '',
        kmDriven: '',
        mileage: '',
        description: '',
        images: [''],
      });
    } catch (err) {
      // console.error(err);
    }
  };

  return (
    <div className='bg-not-so-white w-full border border-bono-purple'>
      
      {/* form header */}
      <div className="text-center py-4 bg-bono-purple">
        <h1 className="text-2xl text-elastic-green font-bold">Add car listing</h1>
      </div>

      <div className="flex items-center justify-center">
          <form onSubmit={handleSubmit} className="m-4 grid gap-6 bg-not-so-white p-10 rounded-xl w-full">
              <input name="make" value={car.make} onChange={handleChange} placeholder="Make" className="p-2 rounded-md border border-bono-purple bg-not-so-white" required />
              <input name="model" value={car.model} onChange={handleChange} placeholder="Model" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
              <input name="year" value={car.year} onChange={handleChange} placeholder="Year" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
              <input name="price" value={car.price} onChange={handleChange} placeholder="Price" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
              {/* dropdown for fuel type */}
              <select name="fuelType" value={car.fuelType} onChange={handleChange} className="p-2 border border-bono-purple bg-not-so-white rounded-md" required>
                <option value="" disabled selected hidden>Fuel Type</option>
                <option value='Bensín'>Bensín</option>
                <option value='Diesel'>Diesel</option>
                <option value='Rafmagns'>Rafmagns</option>
                <option value='Hybrid'>Hybrid</option>
              </select>
              <input name="kmDriven" value={car.kmDriven} onChange={handleChange} placeholder="Kilometers Driven" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
              <input name="mileage" value={car.mileage} onChange={handleChange} placeholder="Drægni" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
              <textarea name="description" value={car.description} onChange={handleChange} placeholder="Description" className="p-2 border border-bono-purple bg-not-so-white rounded-md h-40" required />

              {car.images.map((image, index) => (
                <input
                      key={index}
                      name={`image${index}`}
                      value={image}
                      onChange={(event) => handleImageChange(index, event)}
                      placeholder="Image URL"
                      className="p-2 border border-bono-purple bg-not-so-white rounded-md"
                      // if the image is the last one and not the first image, it is not required 
                      required={index === car.images.length - 1 && index !== 0 ? false : true}
                  />
              ))}

              <div className='bg-bono-purple rounded-md hover:bg-elastic-green'>
                <button type="submit" className="w-full text-elastic-green p-2 rounded-md hover:bg-elastic-green hover:text-bono-purple appearance-none">Add new Car</button>
              </div>

          </form>
      </div>
    </div>
  );

}

export default CarForm;
