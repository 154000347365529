import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import ACarList from '../admin-components/AdminCarList';
import CarForm from '../admin-components/CarForm';
import ConfirmDeletePopup from '../admin-components/ConfirmDeletePopup';
import EditCarPopup from '../admin-components/EditCarPopup';

function AdminPage() {
    const deleteCar = (id) => {
        var token = localStorage.getItem('token');
        setDeletePopupVisible(false);
        axios.delete(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    };

    const [deletePopupVisible, setDeletePopupVisible] = React.useState(false);
    const [editPopupVisible, setEditPopupVisible] = React.useState(false);

    const [selectedCarId, setSelectedCarId] = React.useState(null);

    // security check
    const [loading, setLoading] = React.useState(true);
    const [redirect, setRedirect] = React.useState(false);

    // make fetch request to localhost:5012/checkToken with the token in localstorage
    useEffect(() => {
        var token = localStorage.getItem('token');
        if (token) {
            // server accepts token in this format: const token = req.headers.authorization.split(' ')[1];
            axios.get(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/checkToken`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setRedirect(false);
                    console.log("token is valid!!");
                } else {
                    setLoading(false);
                    setRedirect(true);
                    console.log("token is invalid!!");
                }
            }).catch((err) => {
                setLoading(false);
                setRedirect(true);
                console.log("there was an error!!");
            });
        }else{
            setLoading(false);
            setRedirect(true);
            console.log("token is invalid!!");
        }
    }, []);
            


    return (
        <div>
            {loading === true ? (
                <div>Loading...</div>
            ) : redirect === true ? (
                <div>
                    <div>Redirecting...</div>
                    <Navigate to='/login'/>
                </div>
            ) : (
                <div>
                    <div className='grid grid-cols-3 space-x-2'>
                        <div>
                            <CarForm/>
                        </div>
                        <div className='col-span-2'>
                            <ACarList displayDeletePopup={() => setDeletePopupVisible(true)} displayEditPopup={() => setEditPopupVisible(true)} setCarId={setSelectedCarId}/>
                        </div>
                    </div>
                    <ConfirmDeletePopup visible={deletePopupVisible} handleCancel={() => setDeletePopupVisible(false)} handleDelete={() => deleteCar(selectedCarId)}/>
                    <EditCarPopup currentCarId={selectedCarId} visible={editPopupVisible} closePopup={() => setEditPopupVisible(false)}/>
                </div>
            )}

        </div>
    );
}

export default AdminPage;
