import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className='text-xs md:text-base'>
        <header className="w-full bg-bono-purple p-2">
          <div className="grid grid-cols-3 text-elastic-green items-center">
            <Link to={`/`}>
              <div className='flex ml-4 items-center'>
                <img src='/fullLogo.png' alt='rafmagnsbilar' className='w-14 md:w-20'/>
              </div>
            </Link>
            <div className='flex justify-center items-center'>
              <Link to={`/`}>
                <div className='flex'>
                  {/* <img src='/Icon.png' alt='rafmagnsbilar' className='hidden md:w-8 md:h-8'/> */}
                  <p>Markiðið okkar er að þú sparir að lágmarki 1 milljón</p>
                </div>
              </Link>
            </div>
            <div className='flex justify-end items-center space-x-8'>
              <Link className='hover:text-gray-200'to={`/about`}>Um okkur</Link>
              {/* <Link className='hover:text-gray-200'to={`/admin`}>Admin</Link> */}
            </div>
          </div>
        </header>
    </div>
  );
}

export default Header;
