import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditCarPopup = ({currentCarId, visible, closePopup}) => {

    const [car, setCar] = useState({
        make: '',
        model: '',
        year: '',
        price: '',
        fuelType: '',
        kmDriven: '',
        mileage: '',
        description: '',
        images: [''],
      });

    useEffect(() => {  
        axios.get(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars/${currentCarId}`)
        .then(response => {
            var car = response.data;
            car.images.push('');
            setCar(car);
        })
        .catch(error => {
            // console.error(`Error fetching data: ${error}`);
        })
    }, [currentCarId]);
    
    
      const handleChange = (event) => {
        setCar({
          ...car,
          [event.target.name]: event.target.value,
        });
      };
    
      const handleImageChange = (index, event) => {
        const newImages = [...car.images];
        newImages[index] = event.target.value;
        // add a new image input field if the last one contains a value or take away the last one if it's empty
        if (index === newImages.length - 1 && event.target.value !== '') {
          newImages.push('');
        }
    
        if(index === newImages.length - 2 && event.target.value === '') {
          newImages.pop();
        }
    
        setCar({
          ...car,
          images: newImages,
        });
      };
    
      // make put request to endpoint to update car with correct id
      const handleSubmit = (event) => {
        event.preventDefault();
        var carToSend = car;
        carToSend.images = car.images.filter(image => image !== '');
        
        var token = localStorage.getItem('token');
        event.preventDefault();
        axios.put(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars/${currentCarId}`, carToSend, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
            // console.log(response);  
        })
        .catch(error => {
            // console.error(`Error submitting data: ${error}`);
        })
        closePopup();
      }; 

  return (
    <div>
        {/* if visible, display div else dont */}
        {visible && (
            <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center'>
                <div className='bg-bono-purple p-4 rounded-lg'>
                <div className=' w-full'>
                    {/* form header */}
                    <div className="text-center py-2 bg-bono-purple rounded-lg">
                            <h1 className="text-2xl text-elastic-green font-bold">Edit car listing</h1>
                        </div>

                        <div className="flex items-center justify-center">
                            <form onSubmit={handleSubmit} className="grid gap-6 bg-not-so-white p-10 rounded-xl shadow-md w-full">
                                <div className='grid grid-cols-3'>
                                    <div className='flex flex-col'>
                                        <input name="make" value={car.make} onChange={handleChange} placeholder="Make" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
                                        <input name="model" value={car.model} onChange={handleChange} placeholder="Model" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
                                        <input name="year" value={car.year} onChange={handleChange} placeholder="Year" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
                                        <input name="price" value={car.price} onChange={handleChange} placeholder="Price" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
                                        {/* dropdown for fuel type */}
                                        <select name="fuelType" value={car.fuelType} onChange={handleChange} className="p-2 border border-bono-purple bg-not-so-white rounded-md" required>
                                          <option value="" disabled selected hidden>Fuel Type</option>
                                          <option value='Bensín'>Bensín</option>
                                          <option value='Diesel'>Diesel</option>
                                          <option value='Rafmagns'>Rafmagns</option>
                                          <option value='Hybrid'>Hybrid</option>
                                        </select>
                                        <input name="kmDriven" value={car.kmDriven} onChange={handleChange} placeholder="Kilometers Driven" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
                                        <input name="mileage" value={car.mileage} onChange={handleChange} placeholder="Mileage" className="p-2 border border-bono-purple bg-not-so-white rounded-md" required />
                                    </div>
                                    <div className='col-span-2 flex flex-col justify-between'>
                                        <textarea name="description" value={car.description} onChange={handleChange} placeholder="Description" className="p-2 border border-bono-purple bg-not-so-white rounded-md h-full" required />
                                        {car.images.map((image, index) => (
                                            <input
                                            key={index}
                                            name={`image${index}`}
                                            value={image}
                                            onChange={(event) => handleImageChange(index, event)}
                                            placeholder="Image URL"
                                            className="p-2 border border-bono-purple bg-not-so-white rounded-md"
                                            // if the image is the last one and not the first image, it is not required 
                                            required={index === car.images.length - 1 && index !== 0 ? false : true}
                                            />
                                            ))}
                                    </div>
                                </div>
                                <div className='grid grid-cols-2'>
                                    <div className='bg-red-500'>
                                        <button className="w-full text-white p-2 rounded-md hover:bg-red-600 appearance-none" onClick={() => closePopup()}>Cancel</button>
                                    </div>        
                                    <div className='bg-blue-500'>
                                        <button type="submit" className="w-full text-white p-2 rounded-md hover:bg-blue-600 appearance-none">Update Car</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
    );
};

export default EditCarPopup;
