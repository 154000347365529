import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

function CarDetail({ match }) {
    const [car, setCar] = useState([]);
    const [selectedImage, setSelectedImage] = useState(0);
    const [windowDimensions, setWnidowDimensions] = useState(getWindowDimensions());

    const { id } = useParams(); 

    useEffect(() => {
        const fetchCar = async () => {
            const result = await axios.get(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars/${id}`);
            setCar(result.data);
            console.log(result.data.images);
        };
        fetchCar();
    }, [id])

    useEffect(() => {
        function handleResize() {
            setWnidowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Check if the car data has been fetched and available
    if (!car.model) {
        return <div>Loading...</div>;
    }

    // handle resizing of images
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }


    const imageHeight = windowDimensions.width > 768 ? '600px' : '260px';

    return (
        <div className='text-bono-purple'>
            {/* A div to seperate the images to the left and the cars information to the right */}
            {/* the main div shuold be center aligned */}
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-center gap-12 p-4 rounded-lg">
                {/* A div to display the images */}
                <div className="col-span-2 flex flex-col">
                    {/* A div to display the main image */}
                    {/* the main image should always be the same size even if different images are selected */}
                    <div className="flex justify-center w-full">
                        <img src={car.images[selectedImage]} style={{ width: '1200px', height: imageHeight }} alt={car.make} className="rounded-lg mb-2" />
                    </div>
                    {/* A div to display the smaller images */}
                    <div className="flex justify-left gap-4 w-full">
                        {/* A div to display the smaller images */}
                        {car.images.map((image, index) => (
                            <div key={index} className="flex justify-left">
                                <Link>
                                    <img src={image} alt={car.make} className="w-24 h-24 object-cover rounded-lg mb-2" onClick={() => setSelectedImage(index)}/>
                                    {/* if image is selected, add a line under the image */}
                                    {selectedImage === index && (
                                    <div className='grid grid-rows-1 place-items-center'>
                                        <div className='h-0.5 rounded-lg w-2/3 bg-bono-purple'/>
                                    </div>
                                    )}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                {/* A div to display the cars information aligned to the left */}
                <div className="col-span-2 lg:col-span-1 flex flex-col items-start p-8 bg-not-so-white space-y-8 text-xl rounded-lg border border-bono-purple">
                    {/* class make and model */}
                    <h2 className="text-5xl font-bold">{car.make}</h2>
                    <p className="text-xl font-bold">{car.model}</p>
                    {/* technical information */}
                    <div className='grid grid-cols-2 space-x-4 space-y-4 text-left items-center justify-center border border-bono-purple p-2 w-full rounded-lg'>
                        <div className='flex flex-row items-center space-x-2 pl-4 pt-4'>
                            <img src='https://cdn-icons-png.flaticon.com/128/10090/10090221.png' className='h-5 w-5' alt='rafmagnsbilar.is'/>
                            <p className="">{car.year}</p>
                        </div> 
                        
                        <div className='flex flex-row items-center space-x-2'>
                            <img src='https://cdn-icons-png.flaticon.com/128/8052/8052172.png' className='h-5 w-5' alt='rafmagnsbilar.is'/>
                            <p className="">{car.kmDriven}km</p>
                        </div> 
                        <div className='flex flex-row items-center space-x-2'>
                            <img src='https://cdn-icons-png.flaticon.com/128/5557/5557384.png' className='h-5 w-5' alt='rafmagnsbilar.is'/>
                            <p className="">{car.fuelType}</p>
                        </div>
                        <div className='flex flex-row items-center space-x-2'>
                            <img src='https://cdn-icons-png.flaticon.com/128/84/84570.png' className='h-5 w-5' alt='rafmagnsbilar.is'/>
                            <p className="">{car.mileage}km</p>
                        </div> 
                        <p className="">{car.price.toLocaleString('en-US', { style: 'currency', currency: 'ISK' })}</p>
                    {/* further information */}
                    </div>
                    <div className='text-left space-y-4 p-2'>
                        <p className="text-xl font-bold">Lýsing</p>
                        <p className="text-left">{car.description}</p>
                    </div>
                    <div className='flex w-full h-full justify-center items-center'>
                        <a href='https://us14.list-manage.com/contact-form?u=9750c4321c0a20cbb6694d71c&form_id=3473f1b84a1c2bd94df89f757afe1b26' target='_blank' rel='noreferrer'>
                            <button className='bg-bono-purple hover:bg-elastic-green text-elastic-green hover:text-bono-purple font-bold py-2 px-4 mt-4 rounded-lg'>Senda fyrirspurn</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarDetail;
