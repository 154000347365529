import React from 'react';
import { Link } from 'react-router-dom';

const ACar = ({ car, displayDeletePopup, displayEditPopup, setCarId }) => {

  const handleDeleteSelectCar = () => {
    setCarId(car._id);
    displayDeletePopup();
  }

  const handleEditSelectCar = () => {
    setCarId(car._id);
    displayEditPopup();
  }

  return (
    <div>
      <Link to={`/car/${car._id}`}>
          <div className="bg-no-so-white rounded-lg p-4 shadow grid grid-cols-3">
              <img src={car.images[0]} alt={car.make} className="col-span-2 w-full h-48 object-cover rounded-lg mb-2" />
              <div className='text-left pl-4'>
                  <h2 className="text-lg font-bold">{car.make}</h2>
                  <p className="text-gray-600">{car.model}</p>
                  <p className="text-gray-600">{car.year}</p>
                  <p className="text-gray-600">{car.fuelType}</p>
              </div>
          </div>
      </Link>
      
      <div className="flex justify-center">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg" onClick={() => handleEditSelectCar()}>
            <p>Edit</p>
        </button>
        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg" onClick={() => handleDeleteSelectCar()}> {/* onClick={() => deleteCar(car._id)}> */}
            <p>Delete</p>
        </button>
      </div>
    </div>
  );
};

export default ACar;
