import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Car from './Car';

const CarList = () => {
  const [cars, setCars] = useState([]);
  const [displayedCars, setDisplayedCars] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [priceRange, setPriceRange] = useState([0, 100000000]);
  const [fuelType, setFuelType] = useState('');

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(69420);

  const [formattedCurrentPrice, setFormattedCurrentPrice] = useState("0".toLocaleString('en-US', { style: 'currency', currency: 'ISK' }));
  
  useEffect(() => {
    axios.get(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars/`)
      .then(response => {
        // sort cars in descending order of price
        response.data.sort((a, b) => b.price - a.price);
        setCars(response.data);
        // set min and max price to min and max price of cars
        setMinPrice(Math.min(...response.data.map((car) => car.price)));
        setMaxPrice(Math.max(...response.data.map((car) => car.price)));
      })
      .catch(error => {
        console.error(`Error fetching data: ${error}`);
      })
  }, [cars]);

  useEffect(() => {
    setPriceRange([0, maxPrice]);
  }, [maxPrice]);

  useEffect(() => {
    setFormattedCurrentPrice(Number(priceRange[1]).toLocaleString('en-US', { style: 'currency', currency: 'ISK' }));
  }, [priceRange]);

  // set displayed cars to cars that match search string and price range (and fuel type)
  useEffect(() => {
    setDisplayedCars(cars.filter((car) => {
      return car.make.toLowerCase().includes(searchString.toLowerCase()) || car.model.toLowerCase().includes(searchString.toLowerCase() || car.description.toLowerCase().includes(searchString.toLowerCase()));
    }).filter((car) => {
      return car.price >= priceRange[0] && car.price <= priceRange[1];
    }).filter((car) => {
      return car.fuelType.toLowerCase().includes(fuelType.toLowerCase());
    }));
  }, [cars, searchString, priceRange, fuelType]);

  return (
    <div className='text-xs md:text-base'>
      <div className='flex mb-4 space-x-14 items-center'>
        <div className='items-start grid grid-cols-2 space-x-4 md:flex md:flex-row md:space-x-24 space-y-4 md:space-y-0 md:items-center md:p-4 rounded-lg'>
          <input className='col-span-2 border-2 border-bono-purple bg-not-so-white h-10 px-5 pr-16 rounded-lg text-sm placeholder-bono-purple focus:outline-none' type='search' name='search' placeholder='Leita' onChange={(event) => setSearchString(event.target.value)} />
          {/* slider for price range: */}
          <div className='flex flex-col'>
            <label htmlFor='price'>Hámarksverð</label>
            <input type='range' className='accent-bono-purple' name='price' min={minPrice} max={maxPrice} step='1000000' value={priceRange[1]} onChange={(event) => setPriceRange([0, event.target.value])} />
            <div className='flex justify-between'>
              <span>{formattedCurrentPrice}</span>
            </div>
          </div>
          {/* dropdown for fuel type: */}
          <div className='flex flex-col'>
            <label htmlFor='fuelType'>Eldsneyti</label>
            <select name='fuelType' value={fuelType} onChange={(event) => setFuelType(event.target.value)} className='bg-not-so-white border border-bono-purple text-bono-purple text-sm rounded-lg focus:ring-elastic-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
              <option value=''>Allt</option>
              <option value='Bensín'>Bensín</option>
              <option value='Diesel'>Diesel</option>
              <option value='Rafmagns'>Rafmagns</option>
              <option value='Hybrid'>Hybrid</option>
            </select>
          </div>
        </div>
      </div>
      
      {/* if displayed cars is zero, indicate this */}
      {displayedCars.length === 0 && 
        <div className='flex justify-center'>
          <p className='text-2xl mt-32'>Engir bílar fundust sem passa við leitarskilyrðin</p>
        </div>
      }
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {displayedCars.map((car) => (
          <Car key={car._id} car={car} />
        ))}
      </div>
    </div>
  );
};

export default CarList;
