import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='text-xs md:text-base bg-not-so-white'>
        <header className="w-full bg-bono-purple p-4">
          <div className="grid grid-cols-1 md:grid-cols-3 text-elastic-green">
            <Link to={`/`}>
              <div className='hidden md:flex ml-4 items-center'>
                <img src='/Icon.png' alt='rafmagnsbilar' className='w-8 h-8'/>
              </div>
            </Link>
            <div className='flex justify-center items-center space-x-0 md:space-x-10'>
              <p className='hidden md:flex'>Hafðu samband</p>
              <div className='flex space-x-8'>
                <a href='https://www.facebook.com/Rafmagnsbilar.is'> 
                    <img src='/socialIcons/cdn-images.mailchimp.com_icons_social-block-v2_light-facebook-48.png' className='h-8 w-8' alt='rafmagnsbilar.is'/>
                </a>
                <a href='https://www.instagram.com/rafmagnsbilar/'> 
                    <img src='/socialIcons/cdn-images.mailchimp.com_icons_social-block-v2_light-instagram-48.png' className='h-8 w-8' alt='rafmagnsbilar.is'/>
                </a>
                <a href='rafmagnsbilar@rafmagnsbilar.is'> 
                    <img src='/socialIcons/cdn-images.mailchimp.com_icons_social-block-v2_light-email-48.png' className='h-8 w-8' alt='rafmagnsbilar.is'/>
                </a>
                <p className='hidden md:flex'>Sími 555-0094</p>
              </div>
            </div>
            <div className='hidden md:flex justify-end items-center space-x-8'>
                <p>© 2022 Mare ehf (Kt. 610621-0660)</p>
            </div>
          </div>
        </header>
    </div>
  );
}

export default Footer;
