import React from 'react';
import CarBrand from './CarBrand';

function Logos() {
    // get the path to every image within carBrands folder in the form ./carBrands/aiways.svg
    var images = require.context('../../public/carBrands', true);
    var imagesArray = images.keys().map(function(key){
        return './carBrands/' + key.split('./')[1];
    });

    return (
        <div className='p-24'>
            <p className='text-left font-bold text-2xl p-4 text-bono-purple'>Vinsæl Bílamerki</p>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-4 place-items-center space-y-4">
                {/* map imagesArray to a CarBrand component */}
                {imagesArray.map((image, index) => (
                    <CarBrand key={index} imageUrl={image}/>
                ))}
                {/* <CarBrand imageUrl={'./carBrands/aiways.svg'}/> */}
            </div>
        </div>
    );
}

export default Logos;