import React from 'react';

function AboutUsPage() {
  return (
    <div className='text-bono-purple'>
      <section className='flex flex-col md:grid md:grid-cols-2 p-12 md:p-24 space-x-2 md:space-x-12'>
        <div className=''>
            <img src='https://dim.mcusercontent.com/cs/9750c4321c0a20cbb6694d71c/images/bddfffd6-d9bc-a10e-ced6-9f804bfbf464.png?w=608&dpr=2' alt='Rafmagnsbílar.is' />
        </div>
        <div className='flex flex-col p-4 space-y-4 md:space-y-8'>
            <h1 className='text-lg md:text-3xl font-bold text-left'>Hvað bjóðum við upp á?</h1>
            <p className='text-md md:text-xl text-left'>
                Rafmagnsbílar.is sérhæfa sig í innflutningi á ökutækjum, sérstaklega rafmagns- og plug-in bílum.
                    <br />
                    <br />
                Við bjóðum ennfremur upp á fjármögnun á ökutækinu alla leið frá söluaðila erlendis, í þínar hendur.
                    <br />
                    <br />
                Þú þarft því <b>ekki</b> að greiða út verð bílsins fyrr en við afhendingu í Reykjavík.</p>
        </div>
      </section>

      <section className='flex flex-col md:grid md:grid-cols-2 p-12 md:p-24 space-x-2 md:space-x-12'>
        <div className='flex flex-col justify-start p-4 space-y-8'>
            <h1 className='text-lg md:text-3xl font-bold text-left'>Af hverju Rafmagnsbílar.is?</h1>
            <p className='text-md md:text-xl text-left leading-relaxed'>
            Rafmagnsbílar.is leggja áherslu á faglega og trausta þjónustu við viðskiptavini sína. Búa starfsmenn þess yfir mikilli reynslu í innflutningi, bæði á bílum sem og á öðrum vettvangi.</p>
            <p className='text-center md:text-left'>
              <a href='https://us14.list-manage.com/contact-form?u=9750c4321c0a20cbb6694d71c&form_id=3473f1b84a1c2bd94df89f757afe1b26' target='_blank' rel='noreferrer'>
                <button className='bg-bono-purple text-elastic-green hover:bg-elastic-green hover:text-bono-purple font-bold py-2 px-4 mt-4 rounded-lg'>Senda fyrirspurn</button>
              </a>
            </p>
        </div>
        <div>
            <img src='https://dim.mcusercontent.com/cs/9750c4321c0a20cbb6694d71c/images/2a1cfaf1-5f4c-c08f-b9cf-7e471b2acc44.png?w=608&dpr=2' alt='Rafmagnsbílar.is' />
        </div>
      </section>

      <section className='flex flex-col md:grid md:grid-cols-2 p-12 mt-20 md:mt-0 md:p-24 space-x-2 md:space-x-12'>
        <div>
            <img src='https://dim.mcusercontent.com/cs/9750c4321c0a20cbb6694d71c/images/4266d414-b81a-237d-d3c4-b3ec236bd023.png?w=608&dpr=2' alt='Rafmagnsbílar.is' />
        </div>
        <div className='flex flex-col p-4 space-y-4'>
            <h1 className='text-lg md:text-3xl font-bold text-left'>Hvar erum við til húsa?</h1>
            <div className='flex flex-col space-y-2'>
                <p className='text-md md:text-lg text-left'>Skrifstofan okkar er til húsa að Bíldshöfða 18 (önnur hæð).</p>
                <p className='text-md md:text-lg text-left'>
                    Opið frá 10-18 alla virka daga.
                </p>
                <p className='text-md md:text-lg text-left'>
                    Sími 555-0094
                </p>
                <p className='text-md md:text-lg text-left'>
                    Tölvupóstur rafmagnsbilar@rafmagnsbilar.is
                </p>
                <p className='text-md md:text-lg text-left'>
                    Einnig má senda fyrirspurn með því að smella á hlekkinn.
                </p>
                <br />
                <p className='text-md md:text-lg text-left underline'>
                    <a href="https://us14.list-manage.com/contact-form?u=9750c4321c0a20cbb6694d71c&form_id=3473f1b84a1c2bd94df89f757afe1b26">Senda fyrirspurn</a>
                </p>
            </div>
        </div>
      </section>

      <section className='grid grid-cols-1 p-24 space-x-8 items-center'>
        <div className='flex flex-col justify-start p-4 space-y-4'>
            <h1 className='text-2xl md:text-5xl font-extrabold text-left'>Hvar er best að fylgjast með okkur?</h1>
            <p className='text-xl text-left leading-relaxed'>Viltu tengjast okkur á samfélagsmiðlum?</p>
            <div className='flex space-x-8'>
                <a href='https://www.facebook.com/Rafmagnsbilar.is'> 
                    <img src='https://dim.mcusercontent.com/https/cdn-images.mailchimp.com%2Ficons%2Fsocial-block-v2%2Flight-facebook-48.png?w=1020&dpr=2' className='h-8 w-8' alt='Rafmagnsbílar.is' />
                </a>
                <a href='https://www.instagram.com/rafmagnsbilar/'> 
                    <img src='https://dim.mcusercontent.com/https/cdn-images.mailchimp.com%2Ficons%2Fsocial-block-v2%2Flight-instagram-48.png?w=1020&dpr=2' className='h-8 w-8' alt='Rafmagnsbílar.is' />
                </a>
                <a href='rafmagnsbilar@rafmagnsbilar.is'> 
                    <img src='https://dim.mcusercontent.com/https/cdn-images.mailchimp.com%2Ficons%2Fsocial-block-v2%2Flight-email-48.png?w=1020&dpr=2' className='h-8 w-8' alt='Rafmagnsbílar.is' />
                </a>
            </div>
        </div>
      </section>

      <div className='p-24'>
      <p className='hidden md:flex text-left'>© 2022 Mare ehf (Kt. 610621-0660)</p>
      </div>

    </div>
  );
}

export default AboutUsPage;
