import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ACar from './AdminCar';

const ConfirmDeletePopup = ({visible, handleCancel, handleDelete}) => {

  return (
    <div>
        {/* if visible, display div else dont */}
        {visible && (
            <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center'>
                <div className='bg-gray-800 p-4 rounded-lg'>
                    <div className="py-4">
                        <h1 className="text-left text-2xl text-white font-bold">Are you sure you want to delete this car?</h1>
                        <p className="text-left text-l text-red-400 mt-2">This action cannot be undone!</p>
                    </div>
                    <div className='grid grid-cols-2 space-x-4'>
                        <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg' onClick={() => handleDelete()}>Delete</button>
                        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg' onClick={() => handleCancel()}>Cancel</button>
                    </div>
                </div>
            </div>
        )}
    </div>
    );
};

export default ConfirmDeletePopup;
