import React from 'react';

const CarBrand = ({imageUrl}) => {
  return (
    <div className='h-24 w-24'>
                <img src={`${imageUrl}`} className='h-24 w-24'/>
            </div>
  );
};

export default CarBrand;
