import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ACar from './AdminCar';
import { SERVER_PORT } from '../config.js';

const ACarList = ({displayDeletePopup, displayEditPopup, setCarId}) => {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    axios.get(`https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/cars`)
      .then(response => {
        setCars(response.data);
      })
      .catch(error => {
        console.error(`Error fetching data: ${error}`);
      })
  }, [cars]);

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        {cars.map((car) => (
          <ACar key={car.id} car={car} displayDeletePopup={displayDeletePopup} displayEditPopup={displayEditPopup} setCarId={setCarId}/>
        ))}
      </div>
    </div>
  );
};

export default ACarList;
