import React, { useState } from 'react';
import axios from 'axios';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    // login with the username and password
    try {
        const response = await axios.post('https://rafmagnsbilar-backend-bc3c2b3a8563.herokuapp.com/login', {
            username,
            password,
        });
        // save the token in local storage
        localStorage.setItem('token', response.data.token);
        // redirect to the admin page
        window.location.href = '/admin';
        }
        catch (err) {
            setError(err.response.data.message);
        }
  };

  return (
    <div className='overflow-auto'>
        {/* form to login */}
        <div className='flex flex-col items-center justify-center h-full'>
            <div className='flex flex-col items-center justify-center'>
                <h1 className='text-4xl font-bold text-elastic-green'>Login</h1>
                <form onSubmit={handleSubmit} className='flex flex-col items-center justify-center'>
                    <input
                        type='text'
                        name='username'
                        placeholder='Username'
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        className='p-2 m-2 rounded-md border border-bono-purple bg-not-so-white'
                        required
                    />
                    <input
                        type='password'
                        name='password'
                        placeholder='Password'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        className='p-2 m-2 rounded-md border border-bono-purple bg-not-so-white'
                        required
                    />
                    <button type='submit' className='p-2 m-2 rounded-md border border-bono-purple bg-not-so-white'>
                        Login
                    </button>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Login;
