import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LandingPage from './Views/LandingPage';
import CarDetail from './Views/CarDetailPage';
import Header from './components/Header';
import AdminPage from './Views/AdminPage';
import AboutUsPage from './Views/AboutPage';
import NotFoundPage from './Views/404Page';
import { ThemeProvider } from './components/ThemeContext';
import Footer from './components/Footer';
import Login from './Views/Login';

function App() {
  return (
    <div className='absolute l-0 t-0 w-full h-full bg-not-so-white'>
      <div className="App font-space bg-not-so-white flex flex-col h-screen justify-between">
        <ThemeProvider>
          <Router>
            <Header/>
            <div className='container px-4 py-8 mx-auto'>
              <Routes>
                <Route path='/' element={<LandingPage/>}/>
                <Route path='/car/:id' element={<CarDetail/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/admin' element={<AdminPage/>} />
                <Route path='/about' element={<AboutUsPage/>} />
                <Route path='*' element={<NotFoundPage/>} />
              </Routes>
            </div>
            <div className='flex flex-grow'>

            </div>
            <Footer/>
          </Router>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;
