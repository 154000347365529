import React from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../components/ThemeContext';

function NotFoundPage() {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <div className='flex justify-center items-center mt-48'>
      <h1 className="text-6xl text-center text-bono-purple font-bold">404 Page Not Found</h1>
    </div>
  );
}

export default NotFoundPage;
